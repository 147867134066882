/* Main Container */
.instruction-page {
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1a1a, #333);
  color: #fff;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 40px;
  overflow: auto;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  overflow: auto; /* Ensures scrollability */
}

/* Title Styles */
.title {
  text-align: center;
  font-size: 2.5em;
  color: #f39c12;
  margin-top: 30px;
  margin-bottom: 40px;
  animation: fadeInTitle 1s ease-in-out forwards;
}

/* Section Styles */
.section {
  background-color: #2c3e50;
  padding: 25px;
  margin-bottom: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(20px);
  opacity: 0;
  animation: slideUp 1.2s ease-in-out forwards;
}

.section-title {
  font-size: 1.5em;
  color: #f39c12;
  margin-bottom: 15px;
  position: relative;
  animation: fadeInTitle 1.2s ease-in-out forwards;
}

.section-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 50px;
  height: 3px;
  background-color: #f39c12;
}

/* Instruction List */
.instruction-list {
  list-style-type: none;
  padding: 0;
}

.instruction-list li {
  font-size: 1.1em;
  line-height: 1.8;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
  transform: translateX(-20px);
  opacity: 0;
  animation: fadeInListItem 1.5s ease-in-out forwards;
}

.instruction-list li::before {
  content: '⚡'; /* Custom bullet point */
  position: absolute;
  left: 0;
  color: #f39c12;
  font-size: 1.2em;
}

/* Animations */
@keyframes fadeInTitle {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInListItem {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .title {
    font-size: 2em;
  }

  .section {
    padding: 20px;
  }

  .section-title {
    font-size: 1.3em;
  }

  .instruction-list li {
    font-size: 1em;
  }
}
