/* Main Background */
.contact-background {
    position: relative;
    min-height: 100vh;
    background-color: #0d0d0d;
    overflow: hidden; /* Ensure fireworks stay within the bounds */
}

/* Fireworks Container */
.firework-container {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

/* Firework Animations */
.firework,
.firework::before,
.firework::after {
    --initialSize: 0.5vmin;
    --finalSize: 45vmin;
    --particleSize: 0.2vmin;
    --color1: yellow;
    --color2: khaki;
    --color3: white;
    --color4: lime;
    --color5: gold;
    --color6: mediumseagreen;
    --y: -30vmin;
    --x: -50%;
    --initialY: 60vmin;
    content: "";
    animation: firework 2s infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, var(--y));
    width: var(--initialSize);
    aspect-ratio: 1;
    background: radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 50% 0%,
                radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
                radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
                radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%;
    background-size: var(--initialSize) var(--initialSize);
    background-repeat: no-repeat;
}

/* Specific Firework Positions */
.firework:nth-child(1) {
    left: 20%;
    animation-delay: 0.2s;
}

.firework:nth-child(2) {
    left: 50%;
    animation-delay: 0.5s;
}

.firework:nth-child(3) {
    left: 80%;
    animation-delay: 0.8s;
}

/* Contact Container */
.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 40px;
    min-height: 100vh;
    animation: fadeIn 1s ease-in-out;
    z-index: 2;
}

/* Contact Card */
.contact-card {
    background-color: #222;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    padding: 20px;
    width: 250px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: slideUp 1s ease-in-out;
}

.contact-card h2 {
    margin-bottom: 10px;
    font-size: 22px;
    color: #32CD32;
    animation: fadeIn 1.5s ease-in-out;
}

.contact-card .role {
    font-size: 16px;
    color: #ccc;
    margin-bottom: 15px;
    animation: fadeIn 2s ease-in-out;
}

.contact-card p {
    font-size: 14px;
    color: #aaa;
    margin-bottom: 10px;
    animation: fadeIn 2.5s ease-in-out;
}

.contact-card a {
    color: #32CD32;
    text-decoration: none;
    animation: fadeIn 3s ease-in-out;
}

.contact-card a:hover {
    text-decoration: underline;
}

/* Hover Effect */
.contact-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

/* Animations */
@keyframes firework {
    0% { transform: translate(var(--x), var(--initialY)); width: var(--initialSize); opacity: 1; }
    50% { width: 0.5vmin; opacity: 1; }
    100% { width: var(--finalSize); opacity: 0; }
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        padding: 20px;
    }

    .contact-card {
        width: 100%;
        max-width: 300px;
    }
}
