/* Container */
.notyet-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000;
    overflow: hidden;
    position: relative;
}

/* Warning Sign */
.warning-sign {
    background: radial-gradient(circle, rgba(255, 0, 0, 0.2), rgba(0, 0, 0, 1));
    border: 2px solid #ff0000;
    border-radius: 15px;
    padding: 40px;
    text-align: center;
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.8), 0 0 30px rgba(255, 0, 0, 0.5), 0 0 40px rgba(255, 0, 0, 0.3);
    animation: pulse 2s infinite;
}

.sign-border {
    border: 4px dashed #ff0000;
    padding: 20px;
    border-radius: 10px;
    animation: borderGlow 3s infinite;
}

/* Caption Text */
.notyet-caption {
    font-size: 3rem;
    color: #ff0000;
    text-shadow: 0 0 10px #ff0000, 0 0 20px #ff0000, 0 0 30px #ff0000, 0 0 40px #ff0000;
    animation: fadeInText 2s ease-in-out forwards, textFlicker 2.5s infinite alternate;
}

.notyet-subcaption {
    font-size: 1.5rem;
    color: #ffcccb;
    margin-top: 10px;
    animation: fadeInText 3s ease-in-out forwards, textFlicker 3.5s infinite alternate;
}

/* Fade In Animation */
@keyframes fadeInText {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Glowing Border Animation */
@keyframes borderGlow {
    from {
        box-shadow: 0 0 20px #ff0000;
    }
    to {
        box-shadow: 0 0 40px #ff0000, 0 0 60px #ff0000, 0 0 80px #ff0000;
    }
}

/* Pulse Animation */
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

/* Text Flicker Animation */
@keyframes textFlicker {
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
        opacity: 1;
        text-shadow: 0 0 20px #ff0000, 0 0 30px #ff0000, 0 0 40px #ff0000, 0 0 50px #ff0000;
    }
    20%, 24%, 55% {
        opacity: 0.8;
        text-shadow: none;
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .notyet-caption {
        font-size: 2.5rem;
    }

    .notyet-subcaption {
        font-size: 1.2rem;
    }

    .warning-sign {
        padding: 20px;
    }

    .sign-border {
        padding: 10px;
    }
}
