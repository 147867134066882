/* Dashboard.css */
.dashboard-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    color: #333333;
}

.welcome-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000; /* Black background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 10;
    animation: fadeIn 1s ease-in-out;
}

@keyframes rollingText {
    0% {
        transform: translateX(-100%) rotateY(90deg);
        opacity: 0;
    }
    50% {
        transform: translateX(-50%) rotateY(45deg);
        opacity: 0.7;
    }
    100% {
        transform: translateX(0) rotateY(0deg);
        opacity: 1;
    }
}

.image-container {
    position: relative;
    text-align: center;
}

.welcome-image {
    width: 100%;
    height: auto;
    /* max-width: 600px; Max width for better control */
}

.welcome-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    font-size: 2.5em;
    color: white;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ff00ff, 0 0 40px #ff00ff, 0 0 50px #ff00ff, 0 0 60px #ff00ff, 0 0 70px #ff00ff;
    white-space: nowrap;
    z-index: 20;
}

.letter {
    display: inline-block;
    transform-origin: left center;
    animation: rollingLetter 1s ease-in-out forwards;
    opacity: 0;
}

@keyframes rollingLetter {
    0% {
        transform: translateX(-100%) rotateY(90deg);
        opacity: 0;
    }
    50% {
        transform: translateX(-50%) rotateY(45deg);
        opacity: 0.5;
    }
    100% {
        transform: translateX(0) rotateY(0deg);
        opacity: 1;
    }
}

.welcome-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    color: white;
    background-color: transparent;
    border: 2px solid white;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.welcome-button:hover, .welcome-button:focus {
    background-color: white;
    color: black;
    outline: none; /* Remove default focus outline */
    transform: scale(1.05); /* Slightly scales the button on hover/focus */
}

.dashboard-content {
    z-index: 1;
}

.team-members {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px;
    flex-wrap: nowrap; /* Allow wrapping on small screens */
}

.member {
    text-align: center;
    width: 100%; /* Ensure full width on small screens */
    max-width: 300px; /* Limit maximum width */
    margin-bottom: 20px; /* Space between members */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.member-card {
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 25px;
    text-align: center;
    width: 100%; /* Full width on small screens */
    max-width: 320px; /* Maximum width */
    margin: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    opacity: 0; /* Start off invisible */
    animation-duration: 1s;
    animation-fill-mode: forwards;
    background-color: #f0f4f8;
    color: #333333;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.member-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.member-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.member-card:hover .member-image {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.member-name {
    font-size: 1.6em;
    margin: 15px 0;
    transition: color 0.3s ease;
}

.member-card:hover .member-name {
    color: #0056b3;
}

.member-description {
    font-size: 1em;
    color: #666;
    margin-bottom: 25px;
    line-height: 1.5;
}

.know-more-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 25px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.know-more-button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.team-members .member-card:nth-child(1) {
    animation-name: slideInLeft;
}

.team-members .member-card:nth-child(2) {
    animation-name: slideInRight;
}

.team-name-container {
    text-align: center;
    margin-top: 30px;
}

.team-name {
    font-size: 2.5em;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.15);
    animation: fadeInText 1s ease-in-out;
    position: relative;
    display: inline-block;
}

.team-name::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 5px;
    background-color: #007bff;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease-in-out;
}

.team-name-container:hover .team-name::after {
    transform: scaleX(1);
    transform-origin: left;
}

.problem-name-container {
    text-align: center;
}

.problem-name {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.15);
    animation: fadeInText 1s ease-in-out;
    position: relative;
    display: inline-block;
}

.problem-name::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 5px;
    background-color: red;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease-in-out;
}

.problem-name-container:hover .problem-name::after {
    transform: scaleX(1);
    transform-origin: left;
}

@keyframes fadeInText {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    flex-wrap: wrap; /* Allow wrapping on small screens */
}

.upload-button, .download-button {
    padding: 10px 20px;
    font-size: 1.2em;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.download-button {
    background-color: #1e90ff;
}

.upload-button {
    background-color: #32cd32;
}

.download-button:hover, .upload-button:hover {
    transform: translateY(-2px);
}

.download-button:active, .upload-button:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.download-button:hover {
    background-color: #005cbf;
}

.upload-button:hover {
    background-color: #228b22;
}

.download-button:active {
    background-color: #004080;
}

.upload-button:active {
    background-color: #006400;
}

.loading-text {
    position: absolute;
    top: calc(50% + 100px); /* Slightly below the welcome text */
    left: 50%;
    transform: translate(-50%, -50%);
    color: #32CD32;
    font-size: 1.3em;
    z-index: 15; /* Ensure it stays below the welcome text */
    animation: fadeIn 1s ease-in-out; /* Optional fade-in animation */
}
/* Media Queries for Responsive Design */

/* Mobile Styles */
@media (max-width: 768px) {
    .loading-text{
        font-size: 1em;
    }
    .welcome-text {
        font-size: 1.5em;
    }

    .team-members {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .member {
        width: 90%;
        max-width: none;
    }
}

/* Extra Small Devices */
@media (max-width: 480px) {
    .loading-text{
        font-size: 1em;
    }
    .welcome-text {
        font-size: 1.2em;
    }

    .welcome-button {
        font-size: 1em;
        padding: 8px 16px;
    }

    .member {
        width: 100%;
        margin-bottom: 15px;
    }

    .button-group {
        flex-direction: column;
    }

    .upload-button, .download-button {
        font-size: 1em;
        padding: 10px 20px;
    }
}

#specialText {
    font-size: 20px;
    font-weight: bold;
    color: #FF6F61;
    border-bottom: 2px solid #FF6F61;
    padding-bottom: 4px;
    animation: slideAndFade 1s ease-in-out forwards;
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
  }
  
  @keyframes slideAndFade {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  